import React,  {useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import ApiHelper from '../API/ApiHelper';
import moment from 'moment';
import EventPriceDetails from '../components/EventPriceDetails';
import { Carousel } from 'primereact/carousel';
import football from '../assets/football.jpg';
import basketball from '../assets/basketball.jpg';
import baseball from '../assets/baseball.jpg';
import hockey from '../assets/hockey.jpg';
import concert from '../assets/concert.jpg';
import other from '../assets/misc.jpg';
import soccer from '../assets/soccer.jpg';
import { Tag } from 'primereact/tag';
import THNavbar from '../components/THNavbar';

export default function EventTwo() {
    let { eventId } = useParams();

    const [eventData,setEventData] = useState();
    const [loading,setLoading] = useState(true);
    const [eventDate,setEventDate] = useState(moment());
    const [previewEvents,setPreviewEvents] = useState([]);
    const [venue,setVenue] = useState();
    const [sgDetails,setSgDetails] = useState(<></>);
    const [tmDetails,setTmDetails] = useState(<></>);
    const [shDetails,setShDetails] = useState(<></>);
    const [tpDetails,setTpDetails] = useState(<></>);
    const [vsDetails,setVsDetails] = useState(<></>);
    var api = new ApiHelper();

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

    useEffect(() => {
    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    const initData = async () =>{
        var res = await api.GetEvent(eventId);
        var events = res.data.events;
        if(events.length >0){
            setEventData(events[0]);
            setEventDate(moment(events[0].datetime_local));
            setVenue(events[0].venue);
            var similar = await api.GetSimilar(events[0].type,eventId);
            setPreviewEvents(similar.data);
        }
        setLoading(false);
    }

    useEffect(()=>{
        initData();
    },[])

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const getSource =(type)=>{
        if(type == 'nba' || type.includes('basketball')){
            return basketball
        }
        if(type == 'mls' || type.includes('soccer')){
            return soccer
        }
        if(type == 'nhl' || type.includes('hockey')){
            return hockey
        }
        if(type == 'mlb' || type.includes('baseball')){
            return baseball
        }
        if(type=='nfl' || type.includes('football')){
            return football
        }
        else{
            return concert
        }
    }
    const eventTemplate = (event) => {
        return (
            <a href={"/event/"+event.id} style={{textDecoration:'none'}}>
            <div className='card shadow m-2'>
                <div className='card-body'>
                        <div className="border-1 surface-border border-round m-2 text-center py-1 px-3">
                        <div className="mb-3">
                            <img src={getSource(event.type)} alt={event.name} className="w-6 shadow-2" style={{width:"150px",height:"auto"}} />
                        </div>
                        <div>
                            <h6 className="mb-1 fw-bold">{event.title}</h6>
                            <h6 className="mt-0 mb-3"> Avg: ${event.medianPrice}</h6>
                            <Tag value={event.listingCount +" tickets available"}></Tag>
                        </div>
                    </div>
                </div>
            </div>
            </a>
        );
    };

    if(loading){
        return(<></>)
    }

    if(windowDimensions.width < 900){
        return(<>
        <THNavbar/>
                <div className='container'>
            <div className='row p-2'>
                <div className='col-sm-6 col-12 my-2'>
                        <div className='row'>
                            <div className='card col-12'>
                                <div className='card-header bg-light'>
                                    <div className='fw-bold h4'>
                                            {eventData.title}
                                    </div>
                                    <div className='my-2'>
                                        {
                                            `${eventDate.format("dddd")}, ${eventDate.format("MMMM Do")} @${eventDate.format("hh:mm a")}`
                                        }
                                    </div>
                                    <div>
                                        {
                                            `${venue.name} - ${venue.city}, ${venue.state}`
                                        }
                                    </div>   
                                </div>
                                <div className='card-body'>
                                    <img src={eventData.performers[0].image} alt="" style={{width:"100%",height:"auto"}}/>
                                </div>
                            </div>
                        </div>
                        
                </div>
                <div className='col-sm-6 col-12 px-0'>
                    <EventPriceDetails event={eventData} type="SeatGeek"/>
                    <EventPriceDetails event={eventData} type="TicketMaster"/>
                    <EventPriceDetails event={eventData} type="StubHub"/>
                    <EventPriceDetails event={eventData} type="Vivid Seats"/>
                    <EventPriceDetails event={eventData} type="TickPick"/>
                </div>
                <div className='col-12 card my-2'>
                    <div className='text-center h2 m-2'>
                            Similar Events
                    </div>
                    <div className='card-body p-0'>
                        <Carousel value={previewEvents} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={eventTemplate} autoplayInterval={10000}/>
                    </div>
                </div>
            </div>
        </div>
        </>)
    }

    return (
        <>
        <THNavbar/>
        <div className='container'>
            <div className='row p-2'>
                <div className='col-sm-6 col-12'>
                    <EventPriceDetails event={eventData} type="SeatGeek"/>
                    <EventPriceDetails event={eventData} type="TicketMaster"/>
                    <EventPriceDetails event={eventData} type="StubHub"/>
                    <EventPriceDetails event={eventData} type="Vivid Seats"/>
                    <EventPriceDetails event={eventData} type="TickPick"/>
                </div>
                <div className='col-sm-6 col-12 mt-2'>
                        <div className='row'>
                            <div className='card col-12'>
                                <div className='card-header bg-light'>
                                    <div className='fw-bold h4'>
                                            {eventData.title}
                                    </div>
                                    <div className='my-2'>
                                        {
                                            `${eventDate.format("dddd")}, ${eventDate.format("MMMM Do")} @${eventDate.format("hh:mm a")}`
                                        }
                                    </div>
                                    <div>
                                        {
                                            `${venue.name} - ${venue.city}, ${venue.state}`
                                        }
                                    </div>   
                                </div>
                                <div className='card-body'>
                                    <img src={eventData.performers[0].image} alt="" style={{width:"100%",height:"auto"}}/>
                                </div>
                            </div>
                            <div className='col-12 card mt-2'>
                                <div className='text-center h2 m-2'>
                                        Similar Events
                                </div>
                                <div className='card-body'>
                                    <Carousel value={previewEvents} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={eventTemplate} autoplayInterval={10000}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
            </div>
        </div>
        </>
    )
}
