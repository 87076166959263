import config from '../config.json'
import axios from 'axios';
class ApiHelper {
    constructor(){
        this.url = config.baseURL;
    }

    async searchEvents(searchTerm){
        var res = await axios.get(this.url+"/SeatGeek/search?s="+searchTerm);
        return res;
    }
    async GetPopularEvents(){
        var res = await axios.get(this.url+"/SeatGeek/pop?count=10");
        return res;
    }
    async GetEvent(id){
        var res = await axios.get(this.url+"/SeatGeek/eventdetailsdirect?id="+id);
        return res;
    }
    async GetSimilar(type,id){
        var res = await axios.get(this.url+`/SeatGeek/similar?type=${type}&id=${id}`);
        return res;
    }
    async GetSportEvents(){
        var res = await axios.post(this.url+`/SeatGeek/types?count=4`,[
            'mlb',
            'nfl',
            'nhl',
            'mls',
            'nba'
        ]);
        return res;
    }
    async GetConcertEvents(){
        var res = await axios.get(this.url+`/SeatGeek/type?type=concert&count=4`);
        return res;
    }

}
export default ApiHelper