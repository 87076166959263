import React,  {useEffect,useState} from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from "react-router-dom";
import ApiHelper from '../API/ApiHelper';
import 'react-bootstrap-typeahead/css/Typeahead.css'; 
import logo from '../assets/SeatSavantsLogo.png'
export default function THNavbar() {
    const [searchResults,setSearchResults] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    var api = new ApiHelper();

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

    useEffect(() => {
    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSearch = async (a) =>{
        setIsLoading(true);
        var res = await api.searchEvents(a);
        setSearchResults(res.data);
        setIsLoading(false);
    }

    const handleSelect = (a) =>{
        var e = a[0];
        if(e == null){
            return;
        }
        navigate('/event/'+e.id);
        window.location.reload();
    }

    if(windowDimensions.width<900){
        return(<>
        <div className='container-fluid'>
            <div className='row p-2 bg-primary'>
                <div className='col-3'> 
                <a href="/" className=''>
                    <h6>Seat Savants</h6>
                </a>
                </div>
                <div className='col-9'>
                <div className='w-100'>
                <AsyncTypeahead
                            id="eventSearch"
                            className='shadow-lg shadow-intensity-xl'
                            isLoading={isLoading}
                            onSearch={handleSearch}
                            renderMenuItemChildren={(e)=>(
                                <div className='m-2' >
                                    <div className='row' style={{textWrap:"pretty"}}>
                                        <div className='col-sm-6 col-12'>
                                            <h6>{e.title}</h6>
                                            <div className='text-secondary'>{e.city}</div>
                                        </div>
                                        <div className='col-sm-6 col-12 text-secondary'>
                                            {(new Date(e.dateTimeLocal)).toLocaleString()}
                                            <div className="badge bg-secondary bg-success fw-bold"> Tickets From: ${e.medianPrice}</div>
                                        </div>

                                    </div>
                                    <hr/>
                                </div>
                            )
                            }
                            labelKey="title"
                            options={
                                searchResults
                            }
                            onChange={(selected) => handleSelect(selected)}
                            placeholder={"Search..."}
                        />
                </div>
                </div>
            </div>
        </div>
        </>)
    }

    return (
    <>

    <div className='row shadow-lg shadow-intensity-xl bg-primary'>
        <div className='col-3 d-flex align-items-center justify-content-center'>
        <a className='text-white text-decoration-none' href="/">
            <h3>Seat Savants</h3>
        </a>
        </div>
        <div className='col-6 py-0 row'>
            <div className='col-12 my-3 d-flex justify-content-center align-items-center'>
                <div className='w-100'>
                <AsyncTypeahead
                            id="eventSearch"
                            className='shadow-lg shadow-intensity-xl'
                            isLoading={isLoading}
                            onSearch={handleSearch}
                            renderMenuItemChildren={(e)=>(
                                <div className='m-2' >
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h6>{e.title} <span className="badge bg-secondary ms-3 bg-success fw-bold"> Tickets From: ${e.medianPrice}</span></h6>
                                            <div className='text-secondary'>{e.venueName} - {e.city}</div>
                                        </div>
                                        <div className='col-6 text-end text-secondary'>
                                            {(new Date(e.dateTimeLocal)).toLocaleString()}
                                        </div>

                                    </div>
                                    <hr/>
                                </div>
                            )
                            }
                            labelKey="title"
                            options={
                                searchResults
                            }
                            onChange={(selected) => handleSelect(selected)}
                            placeholder={"Search for you favorite artists or events..."}
                        />
                </div>
            </div>
        </div>
        <div className='col-3 ps-3 d-flex align-items-center '>
        <ul class="navbar-nav d-flex flex-row">
                <li class="nav-item dropdown m-3">
                <a class="nav-link dropdown-toggle text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Concerts
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
                </li>
                <li class="nav-item dropdown m-3">
                <a class="nav-link dropdown-toggle  text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Sports
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
                </li>
                <li class="nav-item dropdown m-3">
                <a class="nav-link dropdown-toggle  text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    The Arts
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
                </li>
                <li class="nav-item dropdown m-3">
                <a class="nav-link dropdown-toggle  text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Family
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
                </li>
            </ul>
        </div>
    </div>
    </>
    )
}
