import React from 'react'
import football from '../assets/football.jpg';
import basketball from '../assets/basketball.jpg';
import baseball from '../assets/baseball.jpg';
import hockey from '../assets/hockey.jpg';
import concert from '../assets/concert.jpg';
import other from '../assets/misc.jpg';
import soccer from '../assets/soccer.jpg';
import { Link } from 'react-router-dom';


export default function EventPopup(props) {
    var name = props.name;
    var location = props.location; 
    var id = props.id;
    var dt = new Date(props.time);
    var type = props.type

    const getSource =(type)=>{
        if(type == 'nba' || type.includes('basketball')){
            return basketball
        }
        if(type == 'mls' || type.includes('soccer')){
            return soccer
        }
        if(type == 'nhl' || type.includes('hockey')){
            return hockey
        }
        if(type == 'mlb' || type.includes('baseball')){
            return baseball
        }
        if(type=='nfl' || type.includes('football')){
            return football
        }
        else{
            return concert
        }
    }
    return (
        <Link to={`/event/${id}`} className='text-decoration-none'>
        <div className=' rounded' style={{width:'225px'}}>
            <div>
                <img src={getSource(type)} alt={name} style={{width:'100%', height:"150px"}} className='rounded' />
            </div>
            <div style={{fontSize:'14px'}} className='p-2'>
                <div className='fw-bold'>{name}</div>
                <div className='text-secondary'>{dt.toLocaleString()}</div>
                <div className='text-secondary'>{location}</div>
            </div>
        </div>
        </Link>
    )
}
