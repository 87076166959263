import React,  {useEffect,useState} from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from "react-router-dom";
import ApiHelper from '../API/ApiHelper';
import 'react-bootstrap-typeahead/css/Typeahead.css'; 
import logo from '../assets/SeatSavantsLogo.png';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import football from '../assets/football.jpg';
import basketball from '../assets/basketball.jpg';
import baseball from '../assets/baseball.jpg';
import hockey from '../assets/hockey.jpg';
import concert from '../assets/concert.jpg';
import other from '../assets/misc.jpg';
import soccer from '../assets/soccer.jpg';
import EventPopup from '../components/EventPopup';

export default function HomeTwo(props) {
    const [eventsLoading,setEventsLoading] = useState(true);
    const [isLoading,setIsLoading] = useState(true);
    const [previewEvents,setPreviewEvents] = useState([]);
    const [searchResults,setSearchResults] = useState([]);
    const [sportEvents,setSportEvents] = useState([]);
    const [concertEvents,setConcertEvents] = useState([]);
    const navigate = useNavigate();
    props.funcNav(false);
    var api = new ApiHelper();
    const initData = async () =>{
        var res = await api.GetPopularEvents();
        var sres = await api.GetSportEvents();
        console.log(sres);
        var cres = await api.GetConcertEvents();

        setSportEvents(sres.data.map((a)=>{
            return (<EventPopup name={a.title} id={a.id} location={a.venueName} type={a.type} time={a.dateTimeLocal} key={a.id}/> )
        }))
        setConcertEvents(cres.data.map((a)=>{
            return (<EventPopup name={a.title} id={a.id} location={a.venueName} type={a.type} time={a.dateTimeLocal} key={a.id}/> )
        }))


        setPreviewEvents(res.data);
        setEventsLoading(false);
        setIsLoading(false);
    }
    const handleSearch = async (a) =>{
        setIsLoading(true);
        var res = await api.searchEvents(a);
        setSearchResults(res.data);
        setIsLoading(false);
    }

    const handleSelect = (a) =>{
        var e = a[0];
        if(e == null){
            return;
        }
        navigate('/event/'+e.id);
    }

    useEffect(()=>{
        initData();
    },[])
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const getSource =(type)=>{
        if(type == 'nba' || type.includes('basketball')){
            return basketball
        }
        if(type == 'mls' || type.includes('soccer')){
            return soccer
        }
        if(type == 'nhl' || type.includes('hockey')){
            return hockey
        }
        if(type == 'mlb' || type.includes('baseball')){
            return baseball
        }
        if(type=='nfl' || type.includes('football')){
            return football
        }
        else{
            return concert
        }
    }
    const eventTemplate = (event) => {
        return (
            <>
            <div className='container border bg-primary rounded h-100'>
                <div className='row h-100'>
                    <div className='col-6 p-3'>
                        <div className='text-white mb-3'>
                            <h3 className="">{event.title}</h3>
                            <div className='text-white'>{new Date(event.dateTimeLocal).toLocaleString()}</div>
                            <div className='text-white'>{event.venueName}</div>
                        </div>
                        <button className='btn btn-outline-info' onClick={()=>{navigate(`/event/${event.id}`)}}>See Tickets</button>
                    </div>
                    <div className='col-6 p-0'>
                        <img src={getSource(event.type)} alt={event.name} style={{width:'100%', height:"100%"}} className='rounded' />
                    </div>
                </div>
            </div>
            </>
        );
    };




  return (
    <div className='container-fluid' style={{backgroundColor:"white",height:'100vh'}}>
        <div className='row'>
            <div className='col-12 bg-primary'>
                <div className='text-white'>
                    <div className='row'>
                        <div className='col-sm-3 col-1'></div>
                        <div className='col-sm-6 col-10'>
                        <nav className="navbar navbar-expand-lg  text-white">
                            <div className="container-fluid">
                                <a className="navbar-brand text-white" href="#">Seat Savants</a>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex flex-row" style={{fontSize:'14px'}}>
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Concerts
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLink">
                                        <li><a className="dropdown-item " href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle  text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Sports
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLink">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle  text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        The Arts
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLink">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle  text-light" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Family
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLink">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </nav>
                        </div>
                        <div className='col-sm-3 col-1'></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row pt-5'>
            <div className='col-sm-3 col-1'>

            </div>
            <div className='col-sm-6 col-10'>
            {
                        !eventsLoading && 
                        <AsyncTypeahead
                            id="eventSearch"
                            className='shadow-lg shadow-intensity-xl m-3 rounded'
                            isLoading={isLoading}
                            onSearch={handleSearch}
                            renderMenuItemChildren={(e)=>(
                                <div className='m-2' style={{textWrap:"pretty"}}>
                                    <div className='row'>
                                        <div className='col-sm-6 col-12'>
                                            <h6>{e.title} </h6>
                                            <div className='text-secondary'>{e.venueName} - {e.city}</div>
                                        </div>
                                        <div className='col-sm-6 col-12 text-end text-secondary'>
                                            {(new Date(e.dateTimeLocal)).toLocaleString()}
                                            <span className="badge bg-secondary ms-3 bg-success fw-bold"> Tickets From: ${e.medianPrice}</span>
                                        </div>

                                    </div>
                                    <hr/>
                                </div>
                            )
                            }
                            labelKey="title"
                            options={
                                searchResults
                            }
                            onChange={(selected) => handleSelect(selected)}
                            placeholder={"Search for you favorite artists or events..."}
                        />
                    }
            </div>
            <div className='col-sm-3 col-1'>
            </div>
        </div>
        <div className='row pt-5'>
                    <div className='col-sm-3 col-1'>

                    </div>
                    <div className='col-sm-6 col-12'>
                    {!eventsLoading && 
                        <>
                            <div className=''>
                                <div className='fw-bold text-center'>
                                </div>
                                <Carousel value={previewEvents} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={eventTemplate} autoplayInterval={3000}/>
                            </div>
                            <hr/>
                            <div>
                                <h6>
                                    Top Sports
                                </h6>
                                <div className='d-flex flex-row justify-content-between'>
                                    {sportEvents}
                                </div>
                            </div>
                            <hr/>
                            <div>
                                <h6>
                                    Top Concerts
                                </h6>
                                <div className='d-flex flex-row justify-content-between'>
                                    {concertEvents}
                                </div>
                            </div>
                            <hr/>
                        </>
                        
                    }
                    </div>
                    <div className='col-sm-3 col-1'>

                    </div>
        </div>
    </div>
  )
}
